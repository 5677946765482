import type { ComponentType } from "react"
import { useState, useEffect, useRef } from "react"

import {
    useMotionValue,
    useSpring,
    useTransform,
    useMotionValueEvent,
    motion,
    MotionValue,
} from "framer-motion"
import { FrameProps } from "framer"

export function withFollowMouse(Component): ComponentType {
    return (props: FrameProps) => {
        const cursorRef = useRef(null)
        const [size, setSize] = useState({
            top: 0,
            left: 0,
            width: 0,
            height: 0,
        })
        const [mouseData, enabled, interactive, pressed] = useMousePosition()
        const springValue = { damping: 60, mass: 1, stiffness: 500 }
        // const x = useSpring(mouseData.x, springValue)
        // const y = useSpring(mouseData.y, springValue)
        const scale = useSpring(mouseData.scale, springValue)

        useEffect(() => {
            setSize({
                top: window.innerHeight / 2,
                left: window.innerWidth / 2,
                width: cursorRef.current.offsetWidth,
                height: cursorRef.current.offsetHeight,
            })
        }, [])

        return (
            <Component
                {...props}
                ref={cursorRef}
                animate={{
                    opacity: enabled ? 1 : 0,
                }}
                style={{
                    ...props.style,
                    position: "fixed",
                    top: -size.height / 2,
                    left: -size.width / 2,
                    x: mouseData.x,
                    y: mouseData.y,
                    scale,
                    pointerEvents: "none",
                }}
            />
        )
    }
}

function useMousePosition() {
    const x = useMotionValue(0)
    const y = useMotionValue(0)
    const scale = useMotionValue(1)

    const [enabled, setEnabled] = useState(false)
    const [pressed, setPressed] = useState(false)
    const [interactive, setInteractive] = useState(false)

    let moveTimer: ReturnType<typeof setTimeout>

    useEffect(() => {
        if (!window.matchMedia("(pointer: fine)").matches) {
            return
        }

        const onMouseMove = (e) => {
            x.set(e.clientX)
            y.set(e.clientY)

            const velX = x.getVelocity()
            const velY = y.getVelocity()

            const s = getScale(velX, velY)
            const defaultScale = 1 - s

            if (
                e.target.nodeName === "BUTTON" ||
                e.target.nodeName === "A" ||
                e.target.parentNode?.nodeName === "A" ||
                e.target.parentNode?.nodeName === "BUTTON"
            ) {
                setInteractive(true)
                scale.set(1.4)
            } else {
                setInteractive(false)
                scale.set(defaultScale)

                moveTimer = setTimeout(() => {
                    scale.set(1)
                }, 10)
            }

            if (e.target.dataset?.noCursor) {
                setEnabled(false)
            } else {
                setEnabled(true)
            }

            if (moveTimer) {
                clearTimeout(moveTimer)
            }
        }

        const onMouseOut = () => {
            setEnabled(false)
        }

        const onMouseDown = () => {
            setPressed(true)
            scale.set(0.8)
        }

        const onMouseUp = () => {
            setPressed(false)
            scale.set(1)
        }

        document.addEventListener("mouseout", onMouseOut)
        document.addEventListener("mousemove", onMouseMove)
        document.addEventListener("mousedown", onMouseDown)
        document.addEventListener("mouseup", onMouseUp)

        const style = document.createElement("style")
        style.textContent = `
            html,
            a,
            button:not(:disabled),
            video {
                cursor: none !important;
            }
        `
        document.head.appendChild(style)

        return () => {
            document.removeEventListener("mouseout", onMouseOut)
            document.removeEventListener("mousemove", onMouseMove)
            document.removeEventListener("mousedown", onMouseDown)
            document.removeEventListener("mouseup", onMouseUp)
            document.head.removeChild(style)
        }
    }, [])

    return [
        {
            x,
            y,
            scale,
        },
        enabled,
        interactive,
        pressed,
    ]
}

// Function for Mouse Move Scale Change
function getScale(diffX, diffY) {
    const distance = Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2))
    return Math.min(distance / 4000, 0.8)
}
